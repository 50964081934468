/* 로그인 필요 페이지 스타일 */
.login-required-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f7fa;
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', sans-serif;
}

.login-required-card {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  padding: 40px 50px;
  text-align: center;
  max-width: 400px;
  width: 100%;
  transition: transform 0.3s ease;
}

.login-required-card:hover {
  transform: translateY(-5px);
}

.login-icon {
  font-size: 48px;
  margin-bottom: 20px;
  animation: pulse 2s infinite;
}

.login-title {
  color: #2c3e50;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
}

.login-message {
  color: #7f8c8d;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 25px;
}

.loading-spinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 3px solid rgba(52, 152, 219, 0.3);
  border-radius: 50%;
  border-top-color: #3498db;
  animation: spin 1s linear infinite;
  margin: 10px auto;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
} 