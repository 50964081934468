/* 관리자 페이지 추가 스타일 */

/* 기본 스타일 - 모든 텍스트가 어두운 색으로 보이도록 설정 */
.admin-layout,
.admin-content,
.admin-main,
.users-page-container,
.projects-page-container,
.dashboard-container,
.admin-table-container,
.admin-card,
.admin-recent-users,
.admin-modal,
.admin-modal-body,
.page-title,
.admin-layout h1, .admin-layout h2, .admin-layout h3, .admin-layout h4, .admin-layout h5, .admin-layout h6, 
.admin-layout p, .admin-layout span, .admin-layout div,
.admin-table tbody tr,
.admin-table td,
.admin-table th {
  color: #333333;
}

/* 관리자 레이아웃 기본 스타일 */
.admin-layout {
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
}

/* 사이드바 스타일 */
.admin-sidebar {
  width: 250px;
  background-color: #2c3e50;
  color: white;
  padding: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  position: relative;
  z-index: 100;
}

/* 로고 부분은 흰색으로 유지 */
.admin-logo {
  text-align: center;
  padding: 25px 20px;
  background-color: #3498db;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.admin-logo h2 {
  color: white !important;
  font-size: 22px;
  margin: 0;
  font-weight: 600;
}

.admin-logo-subtitle {
  color: rgba(255,255,255,0.8) !important;
  font-size: 12px;
  margin-top: 5px;
}

/* 사이드바 네비게이션 스타일 */
.admin-nav {
  flex: 1;
  padding: 0 15px 20px;
}

.admin-nav ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.admin-nav li {
  margin-bottom: 8px;
  border-radius: 4px;
  overflow: hidden;
}

.admin-nav a {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 15px;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: all 0.2s;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
}

.admin-nav a:hover,
.admin-nav a.active {
  background-color: #3498db;
  color: white;
}

.nav-icon {
  font-size: 16px;
  width: 20px;
  text-align: center;
}

/* 사이드바 푸터 */
.admin-sidebar-footer {
  padding: 15px;
  border-top: 1px solid rgba(255,255,255,0.1);
  margin-top: auto;
}

.admin-logout-button {
  width: 100%;
  background: none;
  border: 1px solid rgba(255,255,255,0.2);
  color: rgba(255,255,255,0.8);
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.admin-logout-button:hover {
  background-color: rgba(255,255,255,0.1);
  color: white;
}

.logout-icon {
  font-size: 14px;
}

/* 메인 콘텐츠 영역 */
.admin-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* 헤더 스타일 */
.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  z-index: 10;
}

.admin-header-title {
  font-size: 18px;
  font-weight: 500;
  color: #333;
}

.admin-user-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.admin-user-greeting {
  font-size: 14px;
  color: #666;
  margin-right: 10px;
}

.admin-header-actions {
  display: flex;
  gap: 10px;
}

.admin-logout-button-sm {
  background-color: transparent;
  border: 1px solid #ddd;
  color: #666;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  transition: all 0.2s;
}

.admin-logout-button-sm:hover {
  background-color: #f5f5f5;
  color: #333;
}

/* 메인 콘텐츠 영역 */
.admin-main {
  flex: 1;
  padding: 25px;
  background-color: #f8f9fa;
  overflow-y: auto;
}

/* 공통 스타일 */
.page-title {
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: 600;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
  color: #2c3e50;
}

.users-page-container,
.projects-page-container,
.dashboard-container {
  color: #333;
  max-width: 1200px;
  margin: 0 auto;
  /* padding: 0 15px;/// */
}

/* 테이블 스타일 개선 */
.admin-table-container {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  margin-bottom: 25px;
  border: 1px solid #eee;
}

.admin-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  color: #333;
}

.admin-table th {
  background-color: #3498db;
  color: white !important;
  font-weight: 500;
  padding: 12px 15px;
  text-align: left;
  font-size: 13px;
}

.admin-table th:first-child {
  border-top-left-radius: 6px;
}

.admin-table th:last-child {
  border-top-right-radius: 6px;
}

.admin-table tr {
  color: #333 !important;
}

.admin-table tbody tr:hover {
  background-color: #f5f9fc;
}

.admin-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  color: #333 !important;
}

.admin-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.admin-table tr:last-child td {
  border-bottom: none;
}

.admin-table strong {
  color: #333333 !important;
}

/* 로그인 유형 스타일 */
.login-type {
  display: inline-block;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  min-width: 70px;
  text-align: center;
}

.login-type-kakao {
  background-color: #FEE500;
  color: #191919;
}

.login-type-google {
  background-color: #4285F4;
  color: white;
}

.login-type-naver {
  background-color: #03C75A;
  color: white;
}

.login-type-local {
  background-color: #3498db;
  color: white;
}

/* 상태 배지 스타일 */
.status-badge {
  display: inline-block;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  min-width: 70px;
  text-align: center;
}

.status-active {
  background-color: #2ecc71;
  color: white;
}

.status-inactive {
  background-color: #e74c3c;
  color: white;
}

.status-pending {
  background-color: #f39c12;
  color: white;
}

/* 버튼 스타일 개선 - 세로 배치 */
.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.admin-action-button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  transition: all 0.2s;
  text-align: center;
}

.admin-action-button:hover {
  opacity: 0.9;
}

.admin-view-button {
  background-color: #3498db;
  color: white;
}

.admin-delete-button {
  background-color: #e74c3c;
  color: white;
}

.close-button {
  background-color: #7f8c8d;
  color: white;
}

/* 대시보드 스타일 개선 */
.admin-dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.admin-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border-top: 3px solid #3498db;
}

.admin-card-title {
  margin-top: 0;
  color: #7f8c8d;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.admin-card-value {
  margin-bottom: 0;
  font-size: 28px;
  font-weight: 600;
  color: #2c3e50;
}

.admin-recent-users {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border-top: 3px solid #3498db;
}

.admin-recent-users h3 {
  color: #2c3e50;
  font-size: 18px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

/* 모달 디자인 개선 */
.admin-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
}

.admin-modal {
  background-color: white;
  border-radius: 8px;
  width: 650px;
  max-width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  padding: 25px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  color: #333;
}

.admin-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.admin-modal-header h3 {
  margin: 0;
  color: #2c3e50;
  font-size: 20px;
  font-weight: 600;
}

.admin-modal-close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #7f8c8d;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.admin-modal-close:hover {
  color: #e74c3c;
  background-color: #f9f9f9;
}

.admin-modal-body h4 {
  color: #2c3e50;
  margin: 20px 0 15px;
  font-size: 16px;
  font-weight: 600;
  border-left: 3px solid #3498db;
  padding-left: 10px;
}

.detail-table {
  box-shadow: none;
  margin-bottom: 20px;
}

.detail-table td:first-child {
  width: 140px;
  font-weight: 500;
  color: #3498db;
  background-color: #f9fafc;
}

.detail-table td {
  color: #333 !important;
  padding: 10px 15px;
}

.admin-modal-footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
  border-top: 1px solid #eee;
}

.no-data-message {
  color: #7f8c8d;
  text-align: center;
  padding: 30px;
  font-style: italic;
  background-color: #f9fafc;
  border-radius: 6px;
  font-size: 14px;
}

/* 로딩 표시기 스타일 개선 */
.admin-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 16px;
  color: #3498db;
  font-weight: 500;
  position: relative;
  padding-left: 25px;
}

.admin-loading:before {
  content: '';
  position: absolute;
  left: 0;
  width: 18px;
  height: 18px;
  border: 2px solid rgba(52, 152, 219, 0.3);
  border-radius: 50%;
  border-top-color: #3498db;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* 에러 메시지 스타일 개선 */
.admin-error {
  background-color: #fef2f2;
  color: #c0392b !important;
  padding: 15px !important;
  border-radius: 6px;
  border-left: 3px solid #e74c3c;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 20px;
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .admin-layout {
    flex-direction: column;
  }
  
  .admin-sidebar {
    width: 100%;
    order: 2;
  }
  
  .admin-content {
    order: 1;
  }
  
  .admin-nav ul {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  
  .admin-nav li {
    margin-bottom: 0;
    flex: 1;
    min-width: 120px;
  }
  
  .admin-nav a {
    padding: 10px;
    justify-content: center;
  }
  
  .admin-header {
    padding: 10px 15px;
  }
  
  .admin-header-title {
    font-size: 16px;
  }
  
  .admin-user-greeting {
    display: none;
  }
  
  .admin-table-container {
    padding: 15px;
    overflow-x: auto;
  }
  
  .admin-table th,
  .admin-table td {
    padding: 10px;
  }
  
  .admin-dashboard {
    grid-template-columns: 1fr;
  }
  
  .admin-card {
    padding: 15px;
  }
  
  .page-title {
    font-size: 20px;
  }
  
  .admin-modal {
    padding: 20px;
  }
} 