/* 관리자 페이지 기본 스타일 */
.admin-layout {
  display: flex;
  min-height: 100vh;
}

/* 사이드바 스타일 */
.admin-sidebar {
  width: 250px;
  background-color: #1a1a2e;
  color: white;
  padding: 20px 0;
}

.admin-logo {
  padding: 20px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 20px;
}

.admin-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.admin-nav li {
  margin-bottom: 5px;
}

.admin-nav a {
  display: block;
  padding: 10px 20px;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: background-color 0.3s;
}

.admin-nav a:hover,
.admin-nav a.active {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

/* 메인 콘텐츠 영역 */
.admin-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-main {
  flex: 1;
  padding: 30px;
  background-color: #f5f5f5;
  overflow-y: auto;
}

/* 로그인 페이지 스타일 */
.admin-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.admin-login-form {
  width: 350px;
  padding: 30px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.admin-login-form h2 {
  margin-bottom: 20px;
  text-align: center;
  color: #333333;
}

.admin-form-group {
  margin-bottom: 15px;
  color: #333333;

}

.admin-form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #333333;

}

.admin-form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #333333;

}

.admin-submit-button {
  width: 100%;
  padding: 12px;
  background-color: #1a1a2e;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.admin-submit-button:hover {
  background-color: #16213e;
}

/* 대시보드 스타일 */
.admin-dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.admin-card {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-card-title {
  margin-top: 0;
  color: #666;
  font-size: 14px;
}

.admin-card-value {
  margin-bottom: 0;
  font-size: 28px;
  font-weight: bold;
}

.admin-recent-users {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* 테이블 스타일 */
.admin-table-container {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
}

.admin-table {
  width: 100%;
  border-collapse: collapse;
}

.admin-table th,
.admin-table td {
  padding: 12px 15px;
  text-align: left;
  
}

.admin-table th {
  background-color: #f8f9fa;
  font-weight: 500;
  color: #333333 !important;
}

.admin-table tr {
  border-bottom: 1px solid #eee;
}

.admin-table tr:last-child {
  border-bottom: none;
}

/* 액션 버튼 스타일 */
.admin-action-button {
  padding: 6px 12px;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.admin-view-button {
  background-color: #e7f5ff;
  color: #1c7ed6;
}

.admin-delete-button {
  background-color: #fff5f5;
  color: #e03131;
}

/* 로딩 표시기 */
.admin-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 18px;
  color: #666;
}

/* 사용자 상세 정보 모달 */
.admin-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.admin-modal {
  background-color: white;
  border-radius: 8px;
  width: 600px;
  max-width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.admin-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.admin-modal-close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.admin-modal-body {
  margin-bottom: 20px;
}

.admin-modal-footer {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .admin-layout {
    flex-direction: column;
  }
  
  .admin-sidebar {
    width: 100%;
    padding: 10px 0;
  }
  
  .admin-dashboard {
    grid-template-columns: 1fr;
  }
} 