@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');
/* =============================================================== */
/* ======================================================= LAYOUT  */
/* =============================================================== */
.container {background-color: var(--main-bg); min-height:100vh;display: flex; flex-direction: column; justify-content: space-between;}
.safe-area { padding-left: 10px; padding-right: 10px; }
.main {max-width: 953px; margin:0 auto; padding: 120px 0;}
.init-page-main {max-width: 1440px; margin:0 auto; padding: 120px 0;}
.section {padding: 100px; border:5px solid red;}
.ONE_LINE_ELLIPSIS {width: 90%;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;line-height: 160%;}
.ONE_LINE_ELLIPSIS.FULL_WIDTH {width: 100%;}

/* =============================================================== */
/* ================================================== Loading page  */
/* =============================================================== */
.loading-wrap {width: 100%; height: 100%; position: fixed; left: 0; top:0; display: flex;justify-content: center; align-items: center; flex-direction: column; gap:30px; padding:0 30px;}
.loading-wrap .title { font-size: 30px; text-align: center;}
.loading-bar-wrap{width:100%; height: 20px; margin:0 30px; max-width:884px; margin: 0 auto; background-color: #D9D9D9; border-radius: 8px;}

/* =============================================================== */
/* ================================================= Stepper Page  */
/* =============================================================== */

.step-item { padding: 77px 67px; border-radius: var(--card-radius); background:var(--card-item-bg);box-shadow: 0px 0px 15px 12px #cecece10;}
.step-item__heading--title {font-size:30px; font-weight: 600; margin-bottom: 12px;}
.step-item__heading--description {font-size:20px;}
.step-item__heading--urldescription {font-size:20px;margin-top:30px;margin-bottom:30px}
.step-item__hr {border-color: var(--white); margin-top:30px; margin-bottom:43px;}

.upload-wrap svg {width: 20px;}
.upload-box {margin-bottom: 20px;}
.upload-area {border:1px dashed #3275F8; border-radius: 10px; padding: 20px; position:relative;padding-top:29px; padding-bottom:21px; background-color: #373737;transition: all 0.3s ease-in-out;}
.upload-area.drag-over {
  background-color: #828793; /* 드래그 오버 시 배경색 변경 */
  border: 1px dashed #3275f8; /* 드래그 오버 시 테두리 스타일 변경 */
}
.custom-select-wrap {margin-left: auto; max-width: 122px;position: relative;}

.select-btn {width: 80%; display: flex; align-items: center; border:1px solid var(--white); border-radius: 4px; padding: 7px 10px;}
.select-btn--current-result {display: flex; align-items: center;}
.select-btn--current-result img {width: 15px; margin-right: 7px;}
.select-btn--current-result p {font-size: 16px; line-height: 1  ;}

.select-btn .arrow-box {margin-left:auto;}
.select-btn .arrow-box img {width: 14px; filter: brightness(10);}


.custom-select-list {width:100%; position: absolute; border:1px solid var(--white); border-radius: 4px; background-color: #373737;}
.custom-select-item {display: flex; align-items: center;padding:3px 4px;}
.custom-select-item:hover {background-color: var(--white); color: #111;}
.custom-select-item:hover span { color: #111;}

.upload-inner {width: 100%; height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: flex-end; text-align: center;}
.upload-inner .inner-top {width: 210px; margin-bottom: 42px;}
.upload-inner .inner-text {margin-bottom: 53px;}

.inner-text p {font-size: 20px; color: #777;}
.inner-text p:first-child {font-weight: bold;}
.inner-text p:last-child {font-weight: 300;}

.upload-inner .inner-buttons {display: flex; gap: 21px; align-items: center;}
.upload-inner .upload-btn {border: 1px solid var(--white); border-radius: 10px; display: flex; align-items: center; justify-content: center; color: var(--white); min-width: 150px; gap:12px; height: 54px; font-size: 20px;}
.upload-inner .upload-btn.record img {width: 11px;}
.upload-inner .upload-btn.file img {width: 22px;}

.file-upload-list.checkbox-label {background-color: #3275F8; border: none; margin-bottom: 15px;flex-direction: row; justify-content: space-between;padding:0; padding-left: 23px;opacity: 0.2;}
.file-upload-list.checkbox-label.checked {background-color: #3275F8; border:none; box-shadow: none; padding:0; padding-left: 0px; opacity: 1;}
.upload-list {margin-bottom: 60px;}
.file-item-left {width: 100%; display: flex; align-items: center;}
.file-icon {background:#3275F8;  margin-right: 20px; display: flex; flex-direction: column; align-items: center; margin-top: 5px; margin-bottom: 5px; padding-left:10px;}
.file-icon img{width: 40px;display: flex; flex-direction: column;}
.file-info {background-color: #E4EAFF; width: 100%; display: flex; align-items: center; gap:21px; padding: 20px; border-top:3px solid #3275F8; border-bottom: 3px solid #3275F8; border-right:3px solid #3275F8; border-radius: 0 10px 10px 0;}
.file-info p {color: #111; font-size: 18px; line-height: 1.4;}
.file-info p.file-size {font-size: 14px;}
.file-custom-check {position: absolute; right: 0; top:50%; transform: translate(-100%,-50%);}
.analyze-btn {
  width: 45%;
  background-color: #3a84e3;
  color: var(--white);
  border-radius: 10px;
  padding: 1.5rem 2rem;
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  text-align: left;
  flex-direction:column;
  border: 2px solid #3a84e3;
  gap:1rem
}
.analyze-btn3 {
  width: 60%;
  background-color: #3a84e3;
  color: var(--white);
  border-radius: 10px;
  padding: 1rem 0.5rem;
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  text-align: left;
  flex-direction:column;
  border: 2px solid #3a84e3;
  align-items: center;
  gap:1rem
}
.skip-btn {
  width: 30%;
  background-color: #6c7d74;
  color: var(--white);
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction:column;
  gap:1rem
}
.skip-btn2 {
  width: 30%;
  background-color: #3e3e3e;
  color: var(--white);
  border-radius: 10px;
  border: 2px solid #3e3e3e;
  padding: 1.5rem 2rem;
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  text-align: left;
  flex-direction:column;
  gap:1rem
}
.docx-btn {
  width: 45%;
  background-color: #345ad5;
  color: var(--white);
  border-radius: 10px;
  padding: 1.5rem 2rem;
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  text-align: left;
  flex-direction:column;
  gap:1rem;
  border: 2px solid #345ad5;
}
.analyze-btn2 {
  width: 30%;
  background-color: #3a84e3;
  color: var(--white);
  border-radius: 10px;
  padding: 1.5rem 2rem;
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  text-align: left;
  flex-direction:column;
  border: 2px solid #3a84e3;
  gap:1rem
}

.button-text {
  text-align: left;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

.button-icon-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width:100%
}
.docx-btn2 {
  width: 30%;
  background-color: #345ad5;
  color: var(--white);
  border-radius: 10px;
  padding: 1.5rem 2rem;
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  text-align: left;
  flex-direction:column;
  gap:1rem;
  border: 2px solid #345ad5;
}
.button-icon {
  width: 70px; /* 아이콘 크기 */
  height: 70px; /* 아이콘 크기 */
  vertical-align: middle; /* 이미지와 텍스트의 수직 정렬을 맞추기 위해 */
}
.button-container {
  position: relative; /* 상대적 위치 지정 */
  display: flex;
  justify-content: space-between; /* 양쪽 끝으로 정렬 */
  margin-top: 20px; /* 상단으로부터의 마진 */
}
.result-final{
  text-align: center;
  font-size:24px;
  margin-bottom:24px;
}
@media(max-width:768px) {
  .analyze-btn{
    width: 100%;
    background-color: #3a84e3;
    color: var(--white);
    border-radius: 10px;
    padding: 1.5rem 2rem;
    font-size: 20px;
    font-weight: bold;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    text-align: left;
    flex-direction:column;
    border: 2px solid #3a84e3;
    gap:1rem
  }

  .docx-btn {
    width: 100%;
    background-color: #345ad5;
    color: var(--white);
    border-radius: 10px;
    padding: 1.5rem 2rem;
    font-size: 20px;
    font-weight: bold;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    text-align: left;
    flex-direction:column;
    gap:1rem;
    border: 2px solid #345ad5;
  }

  .button-container {
    display: flex;
    justify-content: space-between; /* 양쪽 끝으로 정렬 */
    margin-top: 20px; /* 상단으로부터의 마진 */
    flex-direction: column;
    gap:1.5rem;
  }

  .analyze-btn2 {
    width: 100%;
    background-color: #3a84e3;
    color: var(--white);
    border-radius: 10px;
    padding: 1.5rem 2rem;
    font-size: 20px;
    font-weight: bold;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    text-align: left;
    flex-direction:column;
    border: 2px solid #3a84e3;
    gap:1rem
  }
  .docx-btn2 {
    width: 100%;
    background-color: #345ad5;
    color: var(--white);
    border-radius: 10px;
    padding: 1.5rem 2rem;
    font-size: 20px;
    font-weight: bold;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    text-align: left;
    flex-direction:column;
    gap:1rem;
    border: 2px solid #345ad5;
  }

  .skip-btn2 {
    width: 100%;
    background-color: #6c7d74;
    color: var(--white);
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction:column;
    gap:1rem
  }

  .analyze-btn3 {
    width: 50%;
    background-color: #3a84e3;
    color: var(--white);
    border-radius: 10px;
    padding: 1rem ;
    font-size: 20px;
    font-weight: bold;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    text-align: center;
    flex-direction:column;
    border: 2px solid #3a84e3;
    align-items: center;
    gap:1rem
  }

  .skip-btn {
    width: 50%;
    background-color: #6c7d74;
    color: var(--white);
    border-radius: 10px;
    padding: 1rem;
    font-size: 20px;
    font-weight: bold;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    flex-direction:column;
    border: 2px solid #6c7d74;
    gap:1rem
  }

}
.insert-box {margin-bottom:40px;}
.insert-box--heading {display: flex; align-items: center; gap:16px; margin-bottom: 14px;}
.insert-box--heading p {color:#4B85F6; font-size: 28px; font-weight: 500;}
.insert-box--heading p span {font-size: 20px; color: #A2A2A2;}
.insert-box--heading .icon-img {width:28px;}

.flex-input-box {display: flex; gap:10px; align-items: center; justify-content: space-between; margin-top: 12px;}
.flex-input-box .custom-input{max-width: 230px;}
.type-title {font-size: 25px; color: #A2A2A2; margin-left: 20px;}
.custom-input {width: 100%; font-size: 22px; padding: 17px 10px 17px 30px; background:#373737; color: var(--white); border:none; border-radius: 10px; box-sizing: border-box;}
.custom-input::placeholder {color:#A2A2A2;}

.flow-control { display: flex; justify-content: space-between; align-items: center; margin-top: 30px;}
.progress-container {width: 100%; max-width: 400px;}
.progress-wrap {width:100%;background:#D9D9D9;border-radius: 8px; margin-bottom: 12px;}
.count-number span {font-size: 14px;}
.step-btn {font-size:20px; padding: 18px 0; width: 150px; display: flex; align-items: center; justify-content: center; font-weight: 600; border-radius: 10px; transition: 0.3s; border: 2px solid transparent;}
.prev-btn {color: var(--white); border: 2px solid var(--white);}
.next-btn {background: #D9D9D9; color: #797979;}

.step-btn:hover {background-color: var(--white); border:2px solid #4B85F6; color: #4B85F6; box-shadow: 0px 0px 0px 2px #4B85F6 inset;}


.step-item__contents .grid-wrap {display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; gap:21px;}
.step-item__contents .grid-item { aspect-ratio: 1/1; max-width: 180px;}

.checkbox-label {display: flex; flex-direction: column;width:100%; height: 100%; background-color: #373737; border-radius: 10px; border:2px solid #595959; padding:12px 14px 23px 14px; align-items: center; justify-content: flex-end; gap:10px; position: relative; transition: 0.3s;}
.checkbox-label.checked {color:#111; background-color: #E4EAFF; border:2px solid #4B85F6; box-shadow:  0px 0px 2px 2px #4B85F6 inset;}
.checkbox-label.checked h4 {color:#111;}
.checkbox-label.checked p {color:#111; font-weight: 600;}
.checkbox-label input {display: none;}
.custom-check {position: absolute; right:0; top:0; transform: translate(-10px, 10px); visibility: hidden;}
.custom-check.active {visibility: visible;}
.custom-check img{width: 28px;}

.work-type {width: 100%;display: flex; align-items: center; justify-content: center;}
.work-type img {width: 60%;}

.work-type.rec img {width: 90%;}
.work-type.marketing img {width: 40%;}
.work-type.translate img {width: 50%;}
.work-type.consulting img {width: 50%;}

/* 수정: work-type-heading 클래스의 특이성을 높이고 !important 추가 */
body .work-type-heading .heading-text {font-size:22px !important; color: white !important;}
body .work-type-heading .heading-text.active {color:#111111 !important; font-weight: bold !important;}

/* STEP 3 - 1*/
.step-item__contents.radio .grid-wrap {display: grid; grid-template-columns: 1fr 1fr; gap:21px;}
.step-item__contents.radio .grid-item {max-width: 100%; aspect-ratio: initial; }

.step-item__contents.radio .checkbox-label {padding:29px 5px 40px 32px;}
.step-item__contents.radio .custom-check {right:0; left: inherit; transform: translate(-10px,10px);}

.step-item__contents.radio .work-type-heading {width: 100%; margin-bottom: 28px;}
.step-item__contents.radio .work-type-heading h4 {font-size: 28px; font-weight: 600; margin-bottom: 8px;}
.step-item__contents.radio .work-type-heading p {font-size: 18px; }
.step-item__contents.radio .work-type-info {width: 100%;}

.info-title {width: 60px; font-size:15px; background: var(--white); display: flex; justify-content: center; align-items: center; border-radius: 50px; color: #111;margin-bottom: 8px;}
.info-title.recommended {background:#4B85F6; color: var(--white); }
.info-desc {font-size:18px; margin-bottom: 20px;}


/* STEP 3 - 2*/
.custom-textarea {width: 100%; height: 274px; padding: 21px 25px; box-sizing: border-box; background:#373737; border:1px solid #595959; border-radius: 10px; color: var(--white);}
.custom-textarea::placeholder {font-size:15px;}
.count-text {display: flex; align-items: center; justify-content: flex-end; margin-top: 10px;}
.url-input {width: 100%; height: 50px; padding: 21px 25px; box-sizing: border-box; background:#373737; border:1px solid #595959; border-radius: 10px; color: var(--white); font-size:15px}

/* STEP 4 */
.step-item__heading__flex {display: flex; justify-content: space-between; align-items: baseline;}
.auto-choose-btn {color:var(--white); background:#4B85F6; border-radius: 10px; padding:14px 20px; font-size:18px;}

/* STEP 5 */
.check-grid {display: grid;gap: 19px;grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;}

.check-grid .checkbox-label { padding:inherit; padding:0px 0 20px 0;}
/* 수정: check-grid 내부의 heading-text 클래스의 특이성 높이기 */
.check-grid .work-type-heading .heading-text {font-size:18px !important; font-weight: 200 !important; color: white !important;}
.check-grid .work-type-heading .heading-text.active {font-weight: 700 !important; color: #111111 !important;}
.check-grid-item .work-type img{width: 100%; max-width: 45px;}
.check-grid-item .checkbox-label {aspect-ratio: 1/1; max-width: 120px;}
.check-grid-item .work-type.active img {filter: brightness(0) !important;}
.check-grid-item .custom-check {left:initial; right: 0; transform: translate(50%,-40%);}
.check-grid .work-type-heading .heading-text-box {
  font-weight: 200;
  display: block; /* 확실히 보이도록 display 속성 설정 */
  width: 80%; height: 10px; padding: 10px 18px; box-sizing: border-box; background:#373737;  border-radius: 0px; color: var(--white); font-size:15px; margin-left:11px;
  overflow: hidden;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: rgb(48, 44, 44);
  padding: 20px;
  border-radius: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  width: auto;
}
.popup-content h2 {
  margin-bottom: 20px; /* 아래쪽 여백을 20px로 설정 */
}
.popup-content input[type="text"] {
  height: 40px; /* 원하는 높이로 설정 */
  padding: 8px 12px; /* 내부 패딩 조정으로 편안한 입력 경험 제공 */
  font-size: 16px; /* 텍스트 크기도 조절하여 균형을 맞출 수 있습니다. */
  margin-bottom: 10px; /* 텍스트 박스 아래 여백 추가 */
}
.button-container {
  display: flex;
  justify-content: space-between; /* 양옆 끝에 버튼을 배치 */
  margin-top: 20px;
}
.add-button {
  border: 1px solid white;
  background-color: black;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
}

.close-button {
  background-color: white;
  color: grey;
  padding: 10px 20px;
  cursor: pointer;
}
.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 119px; /* 버튼의 폭 */
  height: 119px; /* 버튼의 높이 */
}

.icon-button img {
  width: 60px; /* 이미지의 폭 */
  height: 60px; /* 이미지의 높이 */
}


/* =============================================================== */
/* ================================================== Result page  */
/* =============================================================== */
.result-card {margin-bottom: 40px; }
.result-heading {background: #010085; border-radius:30px 30px 0 0;padding: 26px 0 17px 0; display: flex; flex-direction: column; align-items: center; justify-content: center;}
.result-heading h3 {font-size: 35px; font-weight: 500; margin-bottom: 8px;}
.result-heading h4 {font-size: 24px; font-weight: 400;}



.result-contents {background:var(--white); border-radius: 0 0 30px 30px; padding:56px 67px 30px 67px;}
.result-contents p,
.result-contents span {color: #111;}
.result-contents--top{display: flex; justify-content: space-between; align-items: center; margin-bottom: 14px;}

.result-contents--top .head-text {font-size: 40px; font-weight: 500;}
.result-contents--top .sub-text {font-size: 20px;}
.result-contents--top .right img {width:59px;}

.result-contents--center {margin-bottom: 44px;}
.result-contents--center .link {display: flex; align-items: center; justify-content: flex-start; gap:14px;}
.result-contents--center img {width:29px;}
.result-contents--center span {font-size: 20px;}

.result-contents--bottom {margin-bottom: 12px;}

.mini-card-wrap {display: flex; border-radius:10px;}
.mini-card-item {flex:0 0 50%; text-align: center;}
.mini-card-item.time {border:none;}
.mini-card-item__heading {background: #F7F6FF; padding: 15px 0; border: 1px solid #D9D9D9; border-bottom: none;}
.mini-card-item__heading p {font-size: 22px; color: #555555;}
.mini-card-item__contents {padding: 37px 0 17px 0; background: var(--white); border:1px solid #D9D9D9; }
.mini-card-item__contents .head-title {font-size:25px; margin-bottom: 30px;}
.mini-card-item__contents span {font-size:40px; color: inherit; border-bottom: 5px solid black; display: inline-block; line-height: 1.2; font-weight: bold; vertical-align: text-bottom;}

.mini-card-item__contents .sub-title {font-size:20px;color: #777;}

.mini-card-item.price .mini-card-item__heading{border-radius: 10px 0 0 0; border-right: none;}
.mini-card-item.time .mini-card-item__heading{border-radius: 0 10px 0 0;}

.mini-card-item.price .mini-card-item__contents {border-radius: 0 0 0 10px; border-right: none;}
.mini-card-item.time .mini-card-item__contents {border-radius: 0 0 10px 0;}

.result-contents--tab {border: 1px solid #D9D9D9; border-radius: 10px; padding: 17px 20px; background: var(--white);}
.result-contents--tab .tab-item { display: flex; align-content: center; justify-content: space-between;}
.notice-icon {width:20px;}
.result-contents--tab .tab-item .left{display: flex; align-items: center; gap:10px;}
.result-contents--tab .tab-item .left p{color:#777777;}

.result-contents .guide-text {font-size:20px; color:#777777;text-align: center; margin-top: 60px;}
.middle-card {margin-bottom: 60px;}
.middle-card__heading {display: flex; align-items: center; gap: 20px; margin-bottom: 10px;}
.middle-card__heading .sub-text {font-size:20px; color: #555555;}
.middle-card__heading .img {width:23px;}
.middle-card__heading p {font-size:28px;}
.middle-card__contents {background:var(--white); padding: 19px 29px; border-radius: 10px;border:1px solid #d9d9d9;}
.middle-card__contents p {font-size: 22px; line-height: 1.7; font-weight: 300;}

.middle-card__contents hr {margin-top:13px; margin-bottom: 18px;}
.middle-card__contents .more-info { display: flex; align-items: center; gap: 10px; margin-bottom: 15px;}
.middle-card__contents .more-info img {width: 21px;}
.middle-card__contents .more-info span {font-size: 20px; font-weight: 600;}

.link-list li {margin-bottom: 10px;}
.link-list a {display: flex; align-items: center; gap:18px;}
.link-list img {width:20px;}

/* grid table */
.grid-table-wrap {
  width: 100%;
  background-color: var(--white);
  border: 1px solid #e7e7e7;
  border-right: none;
  border-bottom: none;
  padding-right: 30px;
  overflow-x: auto; /* 모바일에서 넘치지 않도록 추가 */
}

.grid-table-item {
  width: 100%; /* 테이블 너비를 100%로 설정 */
  background-color: white;
}

@media (max-width: 768px) {
  .login-image-part {
    display: none; /* 화면 너비가 768px 이하일 때 숨기기 */
  }
}

.table-item {
  width:25%;
  color: #111;
  font-size: 14px;
  line-height: 3;
}

.grid-table-item th,
.grid-table-item td {
  border: 1px solid #e7e7e7;
  text-align: center;
}

.grid-table-item.head .table-item {
  background-color: #b1bace;
}

.table-item.sub-head {
  background-color: #babbbd;
}

.center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rows-3 {
  display: grid;
  grid-template-rows: 2fr 1fr;
}

.col-1-3 {
  grid-template-columns: 1fr 3fr;
}

.table-item.empty {
  text-align: left;
  padding-left: 10px;
}

.table-item:not(.inner) {
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  text-align: center;
}

.result-contents {
  overflow-x: auto; /* 모바일에서 스크롤 가능하도록 추가 */
}

.result-card.last .result-heading {background:var(--white); color: #111; align-items: flex-start;padding-left:67px;padding-right:67px;}
.result-card.last.detail {gap:50px;}
.result-card.last .result-heading h4,
.result-card.last .result-heading h3 {color: inherit;}
.result-card.last .result-heading {padding-bottom: 0;}
.result-card.last .result-heading h4 {margin-bottom: 30px;}
.result-card.last .result-contents { padding-top: 30px;}
/* .hr-wrap {width: 100%; margin-top: 38px;} */
.hr-wrap {width: 100%; margin-top: 0px;}

.result-contents-grid {display: grid; grid-template-columns: repeat(auto-fill, minmax(30%, 1fr)); gap:6px; margin-bottom: 30px;}
.result-box {text-align: center;}
.result-box .head-title {display: flex; align-items: center; justify-content: center; max-width:100px; height: 30px; background-color: #4B85F6;  border-radius: 30px; margin: 0 auto; margin-bottom: 13px; position: relative;}
.result-box .head-title:after{content: ''; width: 100%; height: 1px; background-color: #4B85F6; position: absolute; right:-100%; transform: translate(30%,0);}

.result-contents .result-box.last .head-title::after{display: none;}
.result-box .head-title .step {color: var(--white);}
.result-box .contents-box {border-radius:10px; border:1px solid #4B85F6; padding:34px 15px;}
.result-box .contents-box--heading {font-size:22px; margin-bottom: 17px;}
.result-box .contents-box--desc {font-size:17px; font-weight: 300; word-break: keep-all;}
.consulting-btn {background: #010085; color: var(--white); display: flex; width: 60%; justify-content: center; align-items: center; font-size:20px; border-radius: 10px; padding:17px 0;}
.pdf-btn {background: #010085; color: var(--white); display: flex; width: 30%; justify-content: center; align-items: center; font-size:20px; border-radius: 10px; padding:17px 0;}

/* custom time line */
.custom-time-line  { min-width: 720px;}
.time-line-wrap {display: flex; height: 100%; }
.time-line-item { height: 100%;}
.time-line-item .heading {text-align: right; border-bottom: 1px solid #777; font-size: 14px; padding-bottom: 10px;}
.time-line-grid {height: 100%; border-right: 1px dashed #777; border-bottom: 1px dashed #777; display: grid; grid-template-columns: 1fr; }
.time-line-grid__item { position: relative; min-height: 40px;}


.login-form {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  max-width: 960px;
  margin: auto;
  margin-top: 50px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Pretendard', sans-serif;
}

.login-image-part {
  flex: 1;
  background-size: cover;
}

.login-input-part {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 450px;
  margin: 0 auto;
}

.signup-form h2 {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 10px;
  color: white;
  font-family: 'Pretendard', sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.find-id-form-container .signup-form h2 {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 10px;
  color: white;
  font-family: 'Pretendard', sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.login-form h2 {
  text-align: center;
  margin-bottom: 30px;
  color: white;
  font-family: 'Pretendard', sans-serif;
}

/* 모바일 대응 */
@media (max-width: 768px) {
  .login-form {
      flex-direction: column;
      align-items: center;
  }

  .login-image-part, .login-input-part {
      flex: none;
      width: 100%;
      margin-bottom: 20px;
  }

  .login-input-part {
      padding: 0 20px;
  }
}

.input-group {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Pretendard', sans-serif;
}

.input-group label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #fff;
  font-family: 'Pretendard', sans-serif;
}

.input-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 16px;
  transition: border-color 0.3s;
  font-family: 'Pretendard', sans-serif;
  box-sizing: border-box;
  line-height: 1.2;
  height: 45px;
  margin: 0;
}

.input-group input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Pretendard', sans-serif;
}

.login-button {
  width: 100%;
  padding: 12px;
  background-color: #4B85F6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-bottom: 20px;
  font-family: 'Pretendard', sans-serif;
  height: 45px;
}

.login-button:hover {
  background-color: #3a6fd6;
}

@media (max-width: 768px) {
  .login-button {
    width: 100%;
  }
  .input-group {
    width:100%;
  }
}

.link-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
  font-family: 'Pretendard', sans-serif;
}

.link-button {
  background: none;
  border: none;
  color: #4B85F6;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  padding: 0 10px;
  position: relative;
  transition: color 0.3s;
  font-weight: 500;
  font-family: 'Pretendard', sans-serif;
}

.link-button::after {
  content: '';
  position: absolute;
  margin-top: 10px;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #4B85F6;
  transition: width 0.3s ease;
}

.link-button:hover {
  color: #6A9CFF;
}

.link-button:hover::after {
  width: 100%;
}

@media (max-width: 768px) {
  .link-button {
    font-size: 12px;
    padding: 0 5px;
    white-space: nowrap;
  }
  
  .link-buttons {
    gap: 5px;
  }
  
  .divider {
    margin: 0 2px;
  }
}

.divider {
  color: #4B85F6;
  font-weight: 300;
  font-family: 'Pretendard', sans-serif;
}

.social-login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  width: 100%;
  margin-top: 20px;
  font-family: 'Pretendard', sans-serif;
}

.auth-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 20px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  transition: all 0.2s ease-in-out;
  font-family: 'Pretendard', sans-serif;
}

.auth-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.auth-button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.auth-button-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.auth-button-text {
  color: #333;
  font-size: 14px;
  font-family: 'Pretendard', sans-serif;
}

.email-login {
  background: transparent;
  border: 1px solid #e0e0e0;
}

.email-login:hover {
  background: #f8f8f8;
}

.kakao-login {
  background: #FEE500;
}

.kakao-login:hover {
  background: #FFE812;
}

.naver-login {
  background: #03C75A;
}

.naver-login .auth-button-text {
  color: white;
}

.naver-login:hover {
  background: #02b150;
}

.separator-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  width: 100%;
  font-family: 'Pretendard', sans-serif;
}

.separator {
  color: #888;
  font-size: 14px;
  position: relative;
  padding: 0 15px;
  font-family: 'Pretendard', sans-serif;
}

.separator::before,
.separator::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 100px;
  height: 1px;
  background: #e0e0e0;
}

.separator::before {
  right: 100%;
}

.separator::after {
  left: 100%;
}

@media (max-width: 768px) {
  .social-login-container {
    flex-direction: column;
    align-items: center;
  }
  
  .auth-button {
    width: 100%;
    max-width: 280px;
  }
}

.auth-title {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
  font-family: 'Pretendard', sans-serif;
}

.auth-subtitle {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  margin-bottom: 30px;
  font-family: 'Pretendard', sans-serif;
}

.signup-header {
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Pretendard', sans-serif;
}

.signup-logo {
  width: 120px;
  margin-bottom: 15px;
}

.signup-description {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  line-height: 1.5;
  font-family: 'Pretendard', sans-serif;
  margin-bottom: 20px;
}

.signup-description strong {
  color: #4B85F6;
  font-weight: 700;
}

.signup-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
  padding: 20px 0;
}

.signup-form-container {
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 30px 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  font-family: 'Pretendard', sans-serif;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
}

.signup-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.signup-close-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* 기본 스타일 */
.input-group2 {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Pretendard', sans-serif;
  position: relative;
}

.input-group2 input {
  width: 100%;
  padding: 12px 110px 12px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  transition: border-color 0.3s;
  font-family: 'Pretendard', sans-serif;
  height: 45px;
  box-sizing: border-box;
  line-height: 1.2;
}

.check-button {
  position: absolute;
  right: 5px;
  top: 3px;
  transform: translateY(0);
  padding: 8px 12px;
  background-color: #5b5b5b;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  transition: background-color 0.3s;
  white-space: nowrap;
  font-family: 'Pretendard', sans-serif;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.error-message {
  color: #ffffff;
  margin-bottom: 0;
  background-color: rgba(220, 53, 69, 0.9);
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  animation: fadeInDown 0.4s ease-in-out;
  width: 100%;
  position: relative;
  overflow: hidden;
  font-family: 'Pretendard', sans-serif;
  margin-top: 8px;
  box-sizing: border-box;
}

.error-message::before {
  content: "⚠️";
  margin-right: 8px;
  font-size: 14px;
}

.error-message::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background: linear-gradient(90deg, transparent, #ffffff, transparent);
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.input-group2 input:focus {
    outline: none;
}

.signup-button {
    width: 100%;
    padding: 12px;
    background-color: #4B85F6;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    transition: background-color 0.3s;
    font-family: 'Pretendard', sans-serif;
}

.separator-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 30px;
}

/* .separator-container::before,
.separator-container::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #fff;
  margin: 0 10px;
} */

.separator {
  white-space: nowrap;
}


/* 모바일 대응 */
@media (max-width: 768px) {
    .check-button {
        position: absolute;
        right: 5px;
        top: 3px;
        transform: translateY(0);
        width: 80px;
        height: 39px;
        padding: 8px 10px;
        font-size: 12px;
    }
    
    .input-group2 input {
        padding-right: 100px;
    }
}


.find-id-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: 'Pretendard', sans-serif;
}

.find-id-form-container {
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 30px 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  font-family: 'Pretendard', sans-serif;
  position: relative;
}

.find-id-form-container .signup-form {
  padding: 0;
}

.find-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.find-close-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.function-list p {
  padding: 3px 0;
  font-size: 14px;
  line-height: 1.6;
}
.depth-1 {
  font-weight: bold;
  margin-left: 0;
}

.depth-2 {
  font-weight: normal;
  margin-left: 0px;
}

.depth-3 {
  font-weight: normal;
  margin-left: 0px;
}

.depth-4 {
  font-weight: normal;
  margin-left: 0px;
}

.contents_init {
  display: flex;
  justify-content: center;
  padding: 20px;
  align-items: stretch;
}

.section-left {
  width:55%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  
}

.section-right {
  padding: 20px;
  width:45%;
  display: flex;
  flex-direction: column;
}

.step-item_init {
  padding: 50px 40px;
  border-radius: var(--card-radius);
  background: var(--card-item-bg);
  box-shadow: 0px 0px 15px 12px #cecece10;
  height: 100%;
}

.step-item__heading--title_init {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
}

.step-item__heading--description_init {
  font-size: 16px;
}

.step-item__heading--urldescription_init {
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.step-item__hr_init {
  border-color: var(--white);
  margin-top: 30px;
  margin-bottom: 43px;
}

.upload-wrap_init svg {
  width: 20px;
}

.upload-box_init {
  margin-bottom: 20px;
}

.upload-area_init {
  border: 1px dashed #3275F8;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  padding-top: 29px;
  padding-bottom: 21px;
  background-color: #373737;
  transition: all 0.3s ease-in-out;
}

.upload-area_init.drag-over {
  background-color: #828793; /* 드래그 오버 시 배경색 변경 */
  border: 1px dashed #3275f8; /* 드래그 오버 시 테두리 스타일 변경 */
}

.custom-select-wrap_init {
  margin-left: auto;
  max-width: 122px;
  position: relative;
}

.select-btn_init {
  width: 80%;
  display: flex;
  align-items: center;
  border: 1px solid var(--white);
  border-radius: 4px;
  padding: 7px 10px;
}

.select-btn_init--current-result {
  display: flex;
  align-items: center;
}

.select-btn_init--current-result img {
  width: 15px;
  margin-right: 7px;
}

.select-btn_init--current-result p {
  font-size: 12px;
  line-height: 1;
}

.upload-inner_init {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
}

.upload-inner_init .inner-top {
  width: 120px;
  margin-bottom: 42px;
}

.upload-inner_init .inner-text {
  margin-bottom: 53px;
}

.inner-text_init p {
  font-size: 16px;
  color: #777;
}

.inner-text_init p:first-child {
  font-weight: bold;
}

.inner-text_init p:last-child {
  font-weight: 300;
}

.upload-inner_init .inner-buttons_init {
  display: flex;
  gap: 21px;
  align-items: center;
}

.upload-inner_init .upload-btn {
  border: 1px solid var(--white);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  min-width: 120px;
  gap: 12px;
  height: 40px;
  font-size: 16px;
  margin-top: 16px;
}

.upload-inner_init .upload-btn.record img {
  width: 11px;
}

.upload-inner_init .upload-btn.file img {
  width: 22px;
}

.upload-list_init {
  margin-bottom: 60px;
}

@media (max-width: 768px) {
  .section-left {
    display: none;
  }

  .section-right {
    width: 100%;
  }
}

/* 슬라이드 네비게이션 스타일 */
/* 슬라이드 네비게이션 스타일 */
.dots {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px; /* 맨 아래 가운데에 고정 */
  width: 100%;
}

.dot {
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  cursor: pointer;
}

.dot.active {
  background-color: #373737;
}

.step-item_init_left {
  padding: 50px 40px;
  border-radius: var(--card-radius);
  background: var(--card-item-bg);
  box-shadow: 0px 0px 15px 12px #cecece10;
  background-color: #3367d1;
  background-size: 65% 65%;
  background-position: center;
  background-repeat: no-repeat; 
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* 세로로 아래 정렬 */
  align-items: left; /* 가로로 가운데 정렬 */
  text-align: left;
}

.step-item_init_left p {
  color: white;
}

.step-item_init_left h2 {
  color: white;
  margin-bottom:10px;
}

.login-link-container {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #fff;
  font-family: 'Pretendard', sans-serif;
}

.login-link {
  display: inline-block;
  color: #4B85F6;
  text-decoration: none;
  font-weight: 500;
  position: relative;
  transition: all 0.3s ease;
  padding: 0 5px;
}

.login-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #4B85F6;
  transition: width 0.3s ease;
}

.login-link:hover {
  color: #6A9CFF;
}

.login-link:hover::after {
  width: 100%;
}

.link-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.input-group2.has-error input {
  border-color: rgba(220, 53, 69, 0.9);
  box-shadow: 0 0 0 1px rgba(220, 53, 69, 0.5);
}

.check-button:hover {
  background-color: #4B85F6;
}

@media (max-width: 768px) {
  .check-button {
    position: absolute;
    right: 5px;
    top: 3px;
    transform: translateY(0);
    width: 80px;
    height: 39px;
    padding: 8px 10px;
    font-size: 12px;
  }
  
  .input-group2 input {
    padding-right: 100px;
  }
}

#phoneNumber {
  margin-bottom: 8px;
}

#username {
  margin-bottom: 8px;
}

.button-error-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 8px;
}

.button-error-container .error-message {
  margin-top: 0;
  margin-bottom: 8px;
}